import React, { useContext, useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useModal, CurrencyValue } from '@jutro/components';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { gatewayMessages } from 'gw-capability-gateway-react';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { BusinessConstant, LocalDateUtil, EdgeErrorParser, CoverageUtil, VirtualProductUtil } from 'pv-portals-util-js';
import { ScrollToError } from '@jutro/legacy/wizard-next';
import CoverageFieldsComponent from '../../components/CoverageFieldsComponent/CoverageFieldsComponent';

import PremiumDetailsComponent from '../../components/PremiumDetailsComponent/PremiumDetailsComponent';
import metadata from './PackPage.metadata.json5';

import messages from './PackPage.messages';
import messagesPolicyJob from '../../policyjob.messages';
import styles from './PackPage.module.scss';

function isDifferenceInDaysBiggerThan(date1, date2, days) {
    const effectiveDate = new Date(date2.year, date2.month, date2.day);
    const dayDifference = Math.floor((date1 - effectiveDate) / (3600 * 1000 * 24));
    return dayDifference > days;
}

function structureCustomQuote(submissionVM, affectedQuote, clauses) {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: _.get(submissionVM, 'quoteID.value') || _.get(submissionVM, 'jobID.value'),
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
}

function getCustomQuote(vm, lobPath, quotePath, lobName) {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    const clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
}

function handleError(title, message, showAlert) {
    return showAlert({
        title: title,
        message: message,
        status: 'error',
        icon: 'mi-error-outline',
        confirmButtonText: platformMessages.close
    }).catch(_.noop);
}

function getTwoWheelsVehicles(vehicles) {
    return vehicles.filter((veh) => veh.category === BusinessConstant.VEHICLE_CATEGORY_TWO_WHEELS_AND_SIMILAR);
}

function getTermValue(vehicleCoverages, covName, covTermName) {
    const coverage = vehicleCoverages.coverages.find((cov) => cov.publicID === covName && cov.selected);
    if (_.isUndefined(coverage)) {
        return;
    }
    const covTerm = coverage.terms.find((term) => term.patternCode === covTermName);
    return _.get(covTerm, 'chosenTermValue');
}

const LOB_COVS_MAP = {
    ppvPersonalAuto_PV: {
        materialDamageCov: BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE,
        materialDamageTerm: BusinessConstant.COVERAGE_TERM_DROPDOWN_PATTERNCODE_PPV_MATERIAL_DAMAGE,
        theftCov: BusinessConstant.COVERAGE_PUBLICID_PPV_THEFT,
        theftTerm: BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_THEFT
    },
    pcvCommercialAuto_PV: {
        materialDamageCov: BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE,
        materialDamageTerm: BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE,
        theftCov: BusinessConstant.COVERAGE_PUBLICID_PCV_THEFT,
        theftTerm: BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_THEFT
    }
};

function checkTheftMaterialDeductibleSame(lobName, vehicles, vehiclesCoverages) {
    let areTermsSame = true;
    const covNames = LOB_COVS_MAP[lobName];
    if (covNames) {
        const twoWheelsVehicles = getTwoWheelsVehicles(vehicles);
        if (_.isEmpty(twoWheelsVehicles)) {
            // No two wheels vehicles so no need to check anything
            return areTermsSame;
        }
        twoWheelsVehicles.forEach((vehicle) => {
            const vehicleCoverages = vehiclesCoverages.find((vehCovs) => vehCovs.fixedId === vehicle.fixedId);
            const materialDamageDeductible = getTermValue(
                vehicleCoverages,
                covNames.materialDamageCov,
                covNames.materialDamageTerm
            );
            const theftDeductible = getTermValue(vehicleCoverages, covNames.theftCov, covNames.theftTerm);
            if (
                !_.isUndefined(materialDamageDeductible) &&
                !_.isUndefined(theftDeductible) &&
                materialDamageDeductible !== theftDeductible
            ) {
                areTermsSame = false;
            }
        });
    }

    return areTermsSame;
}

function getVehiclePremium(vehiclePremium, index) {
    return (
        <FormattedMessage
            {...messagesPolicyJob.vehiclePremium}
            values={{
                premium: <CurrencyValue id={`premium${index}`} value={vehiclePremium} className={styles.currencyValue} />
            }}
        />
    );
}

function isTPLCov(publicID) {
    return publicID === 'PPVThirdPartyLiabilityCovPv' || publicID === 'PCVThirdPartyLiabilityCovPvCV';
}

function isCoverageRequired(publicID) {
    return isTPLCov(publicID);
}

function onGetCovFieldsComponent() {
    return CoverageFieldsComponent;
}

function getCoverageContentFn(vehicle) {
    return (covPublicID) => {
        const { bonusMalus_PV: bonusMalus, category } = vehicle;

        if (
            !_.isUndefined(bonusMalus) &&
            (category === BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS ||
                category === BusinessConstant.VEHICLE_CATEGORY_MOBILE_HOME) &&
            isTPLCov(covPublicID)
        ) {
            const overrideProps = {
                bonusMalus: {
                    content: `Bonus Malus: ${bonusMalus}`
                }
            };
            return <MetadataContent uiProps={metadata.coverageContent} overrideProps={overrideProps} />;
        }

        return null;
    };
}

function getMainDriverIndex(vehicleVM, vehicleDriversVM, driversVM) {
    let foundDriverIndex = -1;
    // PPV designatedDriver_PV will be undefined but there will always be a driver, so return default value true
    // PCV should return true or false value
    if (_.get(vehicleVM, 'designatedDriver_PV.value', true)) {
        const primaryVehicleDriver = vehicleDriversVM.children.find(
            (vd) =>
                vd.vehicleID.value === vehicleVM.fixedId.value &&
                (vd.vehicleDriverUsage.value === BusinessConstant.VEHICLE_DRIVER_USAGE_PRI ||
                    vd.vehicleDriverUsage.value.code === BusinessConstant.VEHICLE_DRIVER_USAGE_PRI)
        );
        if (!_.isUndefined(primaryVehicleDriver)) {
            foundDriverIndex = driversVM.children.findIndex(
                (driver) => driver.fixedId.value === primaryVehicleDriver.driverID.value
            );
        }
    }
    return foundDriverIndex;
}

/**
 * Category/Flavour dropdowns
 * 1 - Implement the dropdown fields in the metadata, look for the comment where they should be placed
 * 2 - Create the function to generate the available values for categories (when apply) and flavours,
 *     these values are generated with the data that comes from the VehicleCoverageDTO
 * 3 - In the generateCoveragesOverrides function, pass through props the generated available values, handle the dropdowns visibility or make them disabled if required
 * 4 - Define the function that will be executed when one of the dropdowns and pass it to the metadata through resolvers.
 *     Inside the function, you'll need to perform to actions:
 *     a - Update the model data with updateWizardData
 *     b - Trigger coverages synchronisation
 */

function PackPage(props) {
    const { wizardData: submissionVM, updateWizardData, isSkipping } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { LoadSaveService, CustomQuoteService } = useDependencies(['LoadSaveService', 'CustomQuoteService']);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation,
        initialValidation
    } = useValidation('PackPage');
    const [isPageInitialized, updateIsPageInitialized] = useState(false);
    const [isPeriodStartDateInThePast, updateIsPeriodStartDateInThePast] = useState(false);
    const [loadingClause, updateLoadingClause] = useState();
    const [showRecalculateButton, updateShowRecalculateButton] = useState(false);
  	const defaultActiveTab = 'vehicle0vehicleList';
    const [activeTab, updateActiveTab] = useState(defaultActiveTab);
    const [showErrors, updateShowErrors] = useState(false);
    const [isPremiumDetailsValid, setPremiumDetailsValid] = useState(true);
    const [errorTimestamp, updateErrorTimestamp] = useState(0);

    const { showAlert } = useModal();
    const jobType = _.get(submissionVM, 'baseData.jobType.value.code'); // Submission vs. PolicyChange

    // Only used by submission Job
    const [gettingPaymentDetails, updateGettingPaymentDetails] = useState(jobType !== BusinessConstant.JOB_TYPE_POLICY_CHANGE);
    const [isPaymentDetailsValid, updateIsPaymentDetailsValid] = useState(
        jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE ? true : undefined
    );
    const [isPolicyChange] = useState(jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE);

    const quickQuoteMode = _.get(submissionVM, 'baseData.quoteType.value.code') === 'Quick';
    const lobName =
        _.get(submissionVM, 'baseData.productCode.value') === 'PersonalVehicle_PV'
            ? 'ppvPersonalAuto_PV'
            : 'pcvCommercialAuto_PV';
    const customBranchIdx = submissionVM?.lobData[lobName]?.offerings.children.findIndex((o) => o.branchCode.value === 'CUSTOM');
    const offeringPath = `lobData.${lobName}.offerings.children[${customBranchIdx !== -1 ? customBranchIdx : 0}]`;
    const vehicleCoveragesPath = `${offeringPath}.coverages.vehicleCoverages`;
    const isJobQuoted =
        _.get(
            submissionVM,
            `${jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? 'baseData.periodStatus' : 'status'}.value.code`
        ) === 'Quoted';
    const jobQuotedAndClean = isJobQuoted && !showRecalculateButton;
    const jobNumber =
        jobType === BusinessConstant.JOB_TYPE_SUBMISSION
            ? _.get(submissionVM, 'value.quoteID')
            : _.get(submissionVM, 'value.jobID');
    const customOfferedQuoteBranchIdx = submissionVM?.quoteData?.offeredQuotes?.children.findIndex((quote) => quote.value.branchCode === 'CUSTOM') || 0;


    useEffect(() => {
        const getPacks = async () => {
            if (jobType === BusinessConstant.JOB_TYPE_SUBMISSION && !isJobQuoted) {
                submissionVM.value = await LoadSaveService.getPacks_PV(submissionVM.value, authHeader);
            }
            const vm = viewModelService.changeContext(submissionVM, {
                IsPackScreen: true,
                ContractQuestions: false,
                AdditionalQuestions: false,
                SummaryQuestions: false
            });
            updateWizardData(vm);
            updateIsPageInitialized(true);
        };

        if (!isSkipping) {
            getPacks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    // Resets the invoice value to 0 if tab "Classical system catalog value" is selected for this vehicle
    // Invoice value should only be set if the tab "Classical system invoice value" is selected (PV-20138, PV-20307)
    const resetInvoiceValueIfNecessary = (vehicleCoverages, vehicles) => {
        vehicleCoverages.forEach((vehicleCoverage) => {
            const isClassicalInvoiceSelected = vehicleCoverage.coverages.some((coverage) =>
                CoverageUtil.isClassicalInvoiceSelected(coverage)
            );
            if (!isClassicalInvoiceSelected) {
                const vehicle = vehicles.find((v) => v.publicID === vehicleCoverage.publicID);
                if (vehicle?.invoiceValue || vehicle?.invoiceDate) {
                    // remove InvoiceValue & InvoiceDate for Not-Classical-Invoices
                    vehicle.invoiceValue.amount = null;
                    vehicle.invoiceDate = null;
                }
            }
        });
    };

    const isValidPage = useCallback(
        (premiumDetailsValid = isPremiumDetailsValid) => {
            const vehiclesCoverages = _.get(submissionVM, `${vehicleCoveragesPath}.value`, []);
            const vehicles = _.get(submissionVM, `lobData.${lobName}.coverables.vehicles.value`, []);

            resetInvoiceValueIfNecessary(vehiclesCoverages, vehicles);

            // Check if the current tab and payment details are ok
            // Payment details has a separated flag to allow switch tabs if payment details are invalid
            if (
                !isPolicyChange &&
                (!_.get(submissionVM, 'baseData.periodStartDate.aspects.valid') ||
                    isDifferenceInDaysBiggerThan(
                        new Date(),
                        submissionVM.baseData.periodStartDate.value,
                        BusinessConstant.MAXIMUM_PERIOD_START_DAY
                    ))
            ) {
                updateIsPeriodStartDateInThePast(true);
                return false;
            }

            if (!isComponentValid || !isPaymentDetailsValid) {
                return false;
            }

            if (!premiumDetailsValid) {
                handleError(translator(gatewayMessages.modalError), platformMessagesPV.discountError, showAlert);
                return false;
            }

            if (!checkTheftMaterialDeductibleSame(lobName, vehicles, vehiclesCoverages)) {
                handleError(messages.invalidCoverageOptionTitle, messages.invalidCoverageOptionMsg, showAlert);
                return false;
            }

            return true;
        },
        [isPremiumDetailsValid, submissionVM, vehicleCoveragesPath, lobName, isPolicyChange, isComponentValid, isPaymentDetailsValid, translator, showAlert]
    );

    const shouldSkip = useCallback(() => {
        // On PolicyChange: submissionVM.status
        // On Submission creation: submissionVM.baseData.periodStatus
        if (submissionVM.value.status === 'Proposal_pv' ||
            submissionVM.value.status === 'Bound' ||
            submissionVM.baseData.value.periodStatus === 'Proposal_pv' ||
            submissionVM.baseData.value.periodStatus === 'Bound'
        ) {
            return true;
        }
        if (!isValidPage()) {
            return false;
        }

        if (quickQuoteMode) {
            return false;
        }
        return _.get(submissionVM, 'quoteData.portalPostQuote_PV.value');
    }, [isValidPage, quickQuoteMode, submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldSkip);
    }, [shouldSkip, registerInitialComponentValidation]);

    const onCheckPremiumDetailsValid = useCallback((valid) => {
        updateShowErrors(true);
        updateErrorTimestamp(Date.now());
        setPremiumDetailsValid(valid);
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
            return newSubmissionVM;
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onStartDateValueChange = useCallback(
        (value, path) => {
            const isDateInThePast = isDifferenceInDaysBiggerThan(new Date(), value, BusinessConstant.MAXIMUM_PERIOD_START_DAY);
            if (isDateInThePast) {
                updateIsPeriodStartDateInThePast(true);
            } else {
                updateIsPeriodStartDateInThePast(false);
                const date = LocalDateUtil.addYears_PV(value, 1);
                _.set(submissionVM, 'baseData.periodEndDate.value', {
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    day: date.getDate()
                });
            }
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const applyDateChange = useCallback(async () => {
        try {
            submissionVM.value = await LoadSaveService.getPacks_PV(submissionVM.value, authHeader);
            updateWizardData(submissionVM);
            updateShowRecalculateButton(true);
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            const fullErrorMsg = `${translator(platformMessagesPV.genericTechnicalErrorMsg)}\n${msg}}`;
            handleError(platformMessagesPV.genericTechnicalErrorTitle, fullErrorMsg, showAlert);
        }
    }, [translator, LoadSaveService, authHeader, submissionVM, updateWizardData, showAlert]);

    const onTabChange = useCallback(
        (selectedTabID) => {
            // do not allow tab switch if current tab is invalid
            if (isComponentValid) {
                updateActiveTab(selectedTabID);
                updateShowErrors(false);
            } else {
                updateShowErrors(true);
                updateErrorTimestamp(Date.now());
            }
        },
        [isComponentValid]
    );

    const getjobVM = useCallback(
        (oldSubmissionVM, response, lobPath, quotePath, isSubmission) => {
            const clonedSubmissionVM = viewModelService.clone(oldSubmissionVM);
            if (isSubmission) {
                // Submission retrieves CustomQuoteDTO
                const updatedClauses = _.get(response, `coverages.${lobName}`);
                // Update local offering with new one from xcenter
                _.set(clonedSubmissionVM, `${lobPath}.coverages`, updatedClauses);
                // Update local quote with new one from xcenter
                const status = _.get(response, 'quote.status', 'Draft');
                _.set(clonedSubmissionVM, `${quotePath}.status`, status);
                // Update premium with new one from xcenter
                _.set(clonedSubmissionVM, `${quotePath}.premium`, response.quote.premium);
                // Update local errorsAndWarnings with new one from xcenter
                _.set(clonedSubmissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
            } else {
                // Policy Change retrieves PolicyChangeDataDTO
                clonedSubmissionVM.value = response;
            }
            return clonedSubmissionVM;
        },
        [viewModelService, lobName]
    );

    const syncCovsCall = useCallback(
        async (oldSubmissionVM, basePath) => {
            const quotePath = `quoteData.offeredQuotes.children[${customOfferedQuoteBranchIdx !== -1 ? customOfferedQuoteBranchIdx : 0}]`;
            const customQuote = getCustomQuote(oldSubmissionVM, offeringPath, quotePath, lobName);
            try {
                const response = await CustomQuoteService.updateCustomQuoteCoverages(customQuote, authHeader);
                const newSubmissionVM = getjobVM(
                    oldSubmissionVM,
                    response,
                    offeringPath,
                    quotePath,
                    jobType === BusinessConstant.JOB_TYPE_SUBMISSION
                );
                if (basePath) {
                    const removedFieldsFromVehicleCoverages = ClausesUtil.getRemovedClausesID(
                        oldSubmissionVM,
                        newSubmissionVM,
                        `${basePath}.coverages`
                    );
                    const updatedCovsNotSelected = _.get(oldSubmissionVM, `${basePath}.coverages.value`)
                        .filter((c) => c.updated && !c.selected)
                        .map((c) => c.publicID);
                    disregardFieldValidation([...removedFieldsFromVehicleCoverages, ...updatedCovsNotSelected]);
                }
                updateWizardData(newSubmissionVM);
            } catch (error) {
                const msg = EdgeErrorParser.getErrorMessage(error);
                const fullErrorMsg = `${translator(messages.coverageOptionIssueMsg)}\n${msg}`;
                handleError(messages.coverageOptionIssueTitle, fullErrorMsg, showAlert);
            } finally {
                updateLoadingClause(undefined);
            }
        },
        [
            translator,
            CustomQuoteService,
            authHeader,
            disregardFieldValidation,
            getjobVM,
            jobType,
            lobName,
            offeringPath,
            showAlert,
            customOfferedQuoteBranchIdx,
            updateWizardData
        ]
    );

    const onClauseChange = useCallback(
        async (basePath) => {
            if (isJobQuoted) {
                updateShowRecalculateButton(true);
            }
            await syncCovsCall(submissionVM, basePath);
        },
        [isJobQuoted, syncCovsCall, submissionVM]
    );

    const syncCoverages = useCallback(
        (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(submissionVM, basePath);
            updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
            onClauseChange(ClausesUtil.getParentCoveragePath(basePath));
        },
        [onClauseChange, submissionVM]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncCoverages(value, changedPath);
        },
        [changeSubmission, syncCoverages]
    );

    const onCalculatePremium = useCallback(
        (newSubmissionVM) => {
            updateWizardData(newSubmissionVM);
            updateShowRecalculateButton(false);
        },
        [updateWizardData]
    );

    const onNext = useCallback(async () => {
        if (!isValidPage()) {
            updateShowErrors(true);
            updateErrorTimestamp(Date.now());
            return false;
        }

        if (LoadSaveService.setSelectedVersionOnSubmission_PV) {
            const { quoteID, sessionUUID } = submissionVM.value;
            const offering = _.get(submissionVM, `${offeringPath}.value`, {});
            submissionVM.value = await LoadSaveService.setSelectedVersionOnSubmission_PV(
                quoteID,
                offering.branchName,
                sessionUUID,
                offering.sepaPayment,
                authHeader
            );
        }

        return submissionVM;
    }, [LoadSaveService, authHeader, isValidPage, offeringPath, submissionVM]);

    const onCovFieldChange = useCallback(
        (value, path) => {
            if (isJobQuoted) {
                updateShowRecalculateButton(true);
            }
            return writeValue(value, path);
        },
        [isJobQuoted, writeValue]
    );

    const updateSubmissionAndSyncCoverages = useCallback(
        async (coveragePublicId, newSubmissionVM = submissionVM) => {
            updateLoadingClause(coveragePublicId);
            await LoadSaveService.updateDraftSubmission(newSubmissionVM.value, authHeader);
            await syncCovsCall(newSubmissionVM);
        },
        [LoadSaveService, authHeader, submissionVM, syncCovsCall]
    );

    const generateCoveragesOverrides = useCallback(() => {
        const vehiclesCoveragesVM = _.get(submissionVM, `${vehicleCoveragesPath}.children`);
        const vehicleDriversVM = _.get(submissionVM, `lobData.${lobName}.coverables.vehicleDrivers`);
        const driversVM = _.get(submissionVM, `lobData.${lobName}.coverables.drivers`);
        const vpVersionChecker = VirtualProductUtil.getVersionChecker(submissionVM);
        const overrides = vehiclesCoveragesVM.map((vehicleCoveragesVM, i) => {
            const vehiclesVM = _.get(submissionVM, `lobData.${lobName}.coverables.vehicles.children`, []);
            const vehicleIndex = vehiclesVM.findIndex((v) => v.fixedId.value === vehicleCoveragesVM.fixedId.value);
            const vehicleVM = vehiclesVM[vehicleIndex];
            const vehiclePremium = _.get(vehicleCoveragesVM, 'vehiclePremium.value');
            const driverIndex = getMainDriverIndex(vehicleVM, vehicleDriversVM, driversVM);
            const driverVM = driverIndex !== -1 ? driversVM.children[driverIndex] : undefined;
            return {
                [`coverages${i}`]: {
                    onRenderCoverageContent: getCoverageContentFn(vehicleVM.value),
                    covFieldsProps: {
                        vehicleVM,
                        vehiclePath: `lobData.${lobName}.coverables.vehicles.children[${vehicleIndex}]`,
                        driverVM,
                        driverPath: `lobData.${lobName}.coverables.drivers.children[${driverIndex}]`,
                        onValueChange: onCovFieldChange,
                        updateAndSync: updateSubmissionAndSyncCoverages,
                        coverages: vehicleCoveragesVM.coverages.value,
                        quickQuoteMode,
                        vpVersionChecker
                    }
                },
                [`premiumContainer${i}`]: {
                    visible: vehiclePremium.amount !== 0 && vehiclesCoveragesVM.length > 1 && jobQuotedAndClean
                },
                [`vehiclePremium${i}`]: {
                    content: getVehiclePremium(vehiclePremium, i)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [
        submissionVM,
        vehicleCoveragesPath,
        lobName,
        onCovFieldChange,
        updateSubmissionAndSyncCoverages,
        jobQuotedAndClean,
        quickQuoteMode
    ]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            },
            disabled: !_.isUndefined(loadingClause) || (gettingPaymentDetails && !isPeriodStartDateInThePast),
            loadingClause,
            showAmount: jobQuotedAndClean
        },
        pageTitle: {
            content: jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? translator(messages.pack) : translator(messages.coverages)
        },
        dateFieldContainer: {
            visible: jobType === BusinessConstant.JOB_TYPE_SUBMISSION
        },
        underWritingIssuesMsg: {
            visible: !quickQuoteMode && _.get(submissionVM.value, `quoteData.offeredQuotes[${customOfferedQuoteBranchIdx}].hasBlockingUWIssues`, false),
            quoteId: jobNumber
        },
        setDateButton: {
            disabled: isPeriodStartDateInThePast || !_.get(submissionVM, 'baseData.periodStartDate.aspects.valid')
        },
        noPastDateMsg: {
            visible: isPeriodStartDateInThePast
        },
        packDetailsContainer: {
            visible: !isPeriodStartDateInThePast
        },
        vehicleList: {
          	defaultActiveTab: defaultActiveTab,
            activeTab: activeTab,
            path: `${vehicleCoveragesPath}.children`
        },
        ...generateCoveragesOverrides(),
        paymentDetails: {
            visible: jobType === BusinessConstant.JOB_TYPE_SUBMISSION,
            value: submissionVM,
            isParentPageInitialized: isPageInitialized
        },
        premiumDetails: {
            value: submissionVM,
            showRecalculateButton,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            isValidPage,
            updateShowRecalculateButton,
            updateGettingPaymentDetails,
            applyDateChange,
            onStartDateValueChange,
            onTabChange,
            isCoverageRequired,
            onGetCovFieldsComponent,
            onChangeClause: changeSubmission,
            onSyncCoverages: syncCoverages,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidatePaymentDetails: updateIsPaymentDetailsValid,
            onCalculatePremium,
            onCheckPremiumDetailsValid
        },
        resolveComponentMap: {
            PremiumDetailsComponent
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showCancel={isPolicyChange}
            showPrevious={jobQuotedAndClean}
            showNext={jobQuotedAndClean}
            skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

PackPage.propTypes = wizardProps;
export default PackPage;
