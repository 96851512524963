import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import _ from 'lodash';
import {
    Loader,
    CurrencyValue,
    Chevron,
    formatCurrency,
    formatDate,
    FormattedCurrency,
    withModalContext,
} from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { PaymentComponent, Currency as CurrencyField } from 'gw-components-platform-react';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import AutomaticPaymentPolicyAffectedValueIterableComponent from './AutomaticPaymentPolicyAffectedValueIterableComponent';
import metadata from './BillingAndPayment.metadata.json5';
import accountBillingStyles from './BillingAndPayment.module.scss';
import messages from '../Accounts.messages';
import makePaymentMessages from './MakePayment/MakePayment.messages';
import DelinquencyUtil from 'pv-portals-util-js/DelinquencyUtil';

import { Link } from '@jutro/router';

class AccountBillingAndPayment extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            }),
            billingData: PropTypes.shape({})
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.shape({
                billingDataFromMakePayment: PropTypes.shape({}).isRequired
            }).isRequired
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired
    };

    state = {
        billingData: {},
        loaded: false,
        showInnerLoader: false,
        isSetupPayment: false,
        isSetupPaymentComplete: false,
        isDetailedScheduleOpen: false,
        noBillingData: false,
        xCenter: ''
    };

    localeService = ServiceManager.getService('locale-service');

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(model, 'bc', 'edge.capabilities.billing.dto.BillingInfoDTO');
    };

    getResponse = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData,
                accountDetailsData: { accountNumber },
                billingData
            },
            history: {
                location: { billingDataFromMakePayment }
            }
        } = this.props;

        const responseDataAccountBillingSummary = null; // P&V - not used: await GatewayBillingService.getAccountBillingSummary(accountNumber, authHeader)...
        this.setState({
            loaded: true,
            noBillingData: true
        });

        const model = {};
        const billingInfoVM = this.createVM(model);
        billingInfoVM.paymentGroups = !_.isEmpty(responseDataAccountBillingSummary)
            ? responseDataAccountBillingSummary.paymentGroups
            : [];
        const { _xCenter } = billingInfoVM;
        if (billingDataFromMakePayment) {
            this.setState({
                billingData: billingDataFromMakePayment,
                loaded: true,
                xCenter: _xCenter
            });
        } else {
            const primaryPayerAddress = _.get(accountDetailsData, 'accountBillingSummary.primaryPayer.address', '').split(', ')
                .map((text) => ({ text }));
            const phoneNumberForPrimaryPayer = _.get(accountDetailsData, 'accountBillingSummary.primaryPayer.phoneNumber');
            if (phoneNumberForPrimaryPayer) {
                primaryPayerAddress.push(phoneNumberForPrimaryPayer);
            }
            this.setState({
                billingData: {
                    accountDetailsPrimaryPayerAddress: primaryPayerAddress,
                    getAccountDetails: accountDetailsData,
                    getAccountBillingData: billingData,
                    getAccountBillingDetails: _.isEmpty(responseDataAccountBillingSummary)
                        ? { paymentGroups: [] }
                        : responseDataAccountBillingSummary,
                    paymentGroup: {
                        relatedPolicies: []
                    }
                },
                loaded: true,
                xCenter: _xCenter
            });
        }
    };

    componentDidMount = () => {
        this.getResponse();
    };

    renderAccordionHeader = (isOpen) => {
        const translator = this.context;
        let accordionHeaderTitle = translator(messages.viewPaymentScheduleDetail);
        if (isOpen) {
            accordionHeaderTitle = translator(messages.hidePaymentScheduleDetail);
        }
        return (
            <React.Fragment>
                <Chevron
                    isOpen={isOpen}
                    className={accountBillingStyles.chevronStyle}
                />
                <h5 className={accountBillingStyles.accordionTitle}>{accordionHeaderTitle}</h5>
            </React.Fragment>
        );
    };

    getAccountStatusIcon = (billingData) => {
        const accountBillingData = billingData.getAccountBillingData;
        let isDelinquent = false;
        if (!_.isEmpty(accountBillingData) && !_.isEmpty(accountBillingData.ownedPolicies)) {
            isDelinquent = accountBillingData.ownedPolicies.length > 0
                && _.filter(accountBillingData.ownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }
        if (!_.isEmpty(accountBillingData.unownedPolicies)) {
            isDelinquent = accountBillingData.unownedPolicies.length > 0
                && _.filter(accountBillingData.unownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }
        return isDelinquent ? 'mi-warning' : 'mi-check';
    };

    getAccountStatusClassname = (billingData) => {
        const isDelinquent = !!_.get(billingData, 'getAccountDetails.accountBillingSummary.isDelinquent');

        return classNames(accountBillingStyles.accountBillingIcon, {
            [accountBillingStyles.accountBillingDelinquent]: isDelinquent,
            [accountBillingStyles.accountBillingGoodStanding]: !isDelinquent
        });
    };

    generateSetupPaymentOverrides = () => {
        const { billingData } = this.state;
        const relatedPoliciesPath = 'paymentGroup.relatedPolicies';
        const relatedPolicies = _.get(billingData, relatedPoliciesPath, []);

        const overrides = relatedPolicies.map((relatedPolicy, index) => {
            return {
                [`tabAutomaticPaymentsSetupInvoicePolicy${index}`]: {
                    to: `/policies/${relatedPolicy}/summary`,
                    content: relatedPolicy
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    generateAutomaticBillingOverrides = () => {
        const translator = this.context;
        const { billingData } = this.state;
        const paymentGroupsPath = 'getAccountBillingDetails.paymentGroups';
        const paymentGroups = _.get(billingData, paymentGroupsPath, []);
        const invoiceSummaryPath = 'invoiceSummary';

        const overrides = paymentGroups.map((paymentGroup, index) => {
            const invoiceSummary = _.get(paymentGroup, invoiceSummaryPath);
            return {
                [`tabAutomaticHeaderTitle${index}`]: {
                    content: translator(messages.invoiceStream, {
                        periodicity: paymentGroup.displayName
                    })
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummaryText${index}`]: {
                    content: paymentGroup.paymentInstrumentSummary
                        ? translator(messages.automaticPaymentsEnabled)
                        : translator(messages.automaticPaymentsNotEnabled)
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummaryIconCheck${index}`]: {
                    visible: _.has(paymentGroup, 'paymentInstrumentSummary')
                },
                [`tabAutomaticPaymentsPaymentInstrumentSummarySetup${index}`]: {
                    visible: !_.has(paymentGroup, 'paymentInstrumentSummary'),
                    onClick: () => {
                        this.handleSetupAutomaticPayment(index);
                    }
                },
                [`tablePaymentScheduleDetail${index}`]: {
                    data: paymentGroup.invoiceSummary
                },
                [`tabAutomaticPaymentNextDue${index}`]: {
                    label: this.getFirstUnpaidBillDueDate(invoiceSummary, 'label'),
                    value: this.getFirstUnpaidBillDueDate(invoiceSummary, 'value')
                },
                [`tabAutomaticPaymentPastDue${index}`]: {
                    label: this.getOverdueStatEarliestDue(invoiceSummary, 'label'),
                    value: this.getOverdueStatEarliestDue(invoiceSummary, 'value')
                },
                [`tabAutomaticPaymentTotalDue${index}`]: {
                    value: this.getInvoiceStreamTotalDue(invoiceSummary)
                },
                [`accordionPaymentScheduleDetailsSection${index}`]: {
                    renderHeader: this.renderAccordionHeader
                },
                [`tabAutomaticPaymentPolicyAffectedValuesComponent${index}`]: {
                    data: {
                        paymentGroup: paymentGroup,
                        policySummaries: billingData.getAccountDetails.policySummaries
                    }
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    getCell = (item, index, property) => {
        return item[property.id];
    };

    getPolicyLink = (item, index, property) => {
        return <Link to={`/policies/${item[property.id]}/summary`}>{item[property.id]}</Link>;
    };

    renderFormattedCurrencyField = (item, index) => {
        return (
            <div className={accountBillingStyles.currencyContainer}>
                <CurrencyField
                    id={`currency_${index}`}
                    value={item}
                    readOnly
                    hideLabel
                />
            </div>
        );
    };

    renderFormattedDateFieldPV = (item) => {
        const dateValue = (typeof item === 'string') ? this.props.intl.formatDate(new Date(item), { year: 'numeric', month: 'short', day: 'numeric' }) : '-';
        return (
            <div>
                {dateValue}
            </div>
        );
    };

    getFormattedDatePV = (item, index, property) => {
        return this.renderFormattedDateFieldPV(item[property.id], index);
    };

    getFormattedCurrency = (item, index, property) => {
        return this.renderFormattedCurrencyField(item[property.id], index);
    };

    getFormattedCurrencyPaymentSchedule = (item, index, property) => {
        const { amount, currency } = item.breakdown[property.id];
        return this.renderFormattedCurrencyField({ amount, currency }, index);
    };

    getFormattedCurrencyCharges = (item) => {
        const { charges, taxes } = item.breakdown;
        return (
            <CurrencyValue
                amount={charges.amount + taxes.amount}
                currency={charges.currency}
                showFractions
            />
        );
    };

    getBillStatus = (item, index, property) => {
        const translator = this.context;
        return translator(messages[item[property.id]]);
    };

    getAltBillingAccount = (item, index, property) => {
        return item[property.id] || '-';
    };

    getDelinquenyLevel = (item) => {
        const { delinquencyLevel_PV : delinquencyLevel} = item;
        const translator = this.context;
        return translator(DelinquencyUtil.getDelinquencyLevel(delinquencyLevel, messages.inGoodStanding));
    };

    getDelinquenyLevelDate = (item) => {
        const { delinquencyDate_PV : delinquencyDate } = item;
        return DelinquencyUtil.getDelinquencyDate(delinquencyDate, this.props.intl);
    };

    aggregateBilling = (invoiceSummary) => {
        if (invoiceSummary.length > 0) {
            let totalAmountDue = 0;
            let earliestDue = new Date(invoiceSummary[0].dueDate);
            _.each(invoiceSummary, (invoice) => {
                totalAmountDue += invoice.amountDue.amount;
                const newDueDate = new Date(invoice.dueDate);
                if (newDueDate.getTime() < earliestDue.getTime()) {
                    earliestDue = newDueDate;
                }
            });

            return {
                amountDue: {
                    amount: totalAmountDue,
                    currency: invoiceSummary[0].amountDue.currency
                },
                dueDate: earliestDue
            };
        }
        return null;
    };

    getFirstUnpaidBillDueDate = (invoiceSummary, returnType) => {
        const translator = this.context;
        const unpaidStat = _.minBy(
            _.filter(invoiceSummary, (invoice) => {
                return invoice.invoiceStatus === 'planned';
            }),
            (invoice) => {
                return new Date(invoice.dueDate).getTime();
            }
        );

        if (unpaidStat) {
            if (returnType === 'label') {
                return translator(messages.nextDueDate,
                    {
                        firstUnpaidBillDueDate: formatDate(this.props.intl, {}, unpaidStat.dueDate)
                    });
            }

            return unpaidStat.amountDue;
        }

        if (returnType === 'label') {
            return translator(messages.nextDue);
        }
        return ({ amount: 0, currency: this.localeService.getDefaultCurrencyCode() });
    };

    getSetupPaymentNextPayment = (invoiceSummary) => {
        const translator = this.context;
        const { amount, currency } = this.getFirstUnpaidBillDueDate(invoiceSummary, 'value');
        const currencyAmount = formatCurrency(this.props.intl, FormattedCurrency.defaultProps, { amount, currency });
        return translator(
            messages.setupPaymentConfirmNextPayment,
            { currencyAmount: currencyAmount }
        );
    };

    getOverdueStatEarliestDue = (invoiceSummary, returnType) => {
        const translator = this.context;
        const overdueStatus = this.aggregateBilling(_.filter(invoiceSummary, (invoice) => { return invoice.invoiceStatus === 'due'; }));
        if (overdueStatus) {
            if (returnType === 'label') {
                return translator(messages.pastDueDate,
                    {
                        overdueStatEarliestDate: formatDate(this.props.intl, {}, overdueStatus.dueDate)
                    });
            }
            return overdueStatus.amountDue;
        }

        if (returnType === 'label') {
            return translator(messages.pastDue);
        }
        return ({ amount: 0, currency: this.localeService.getDefaultCurrencyCode() });
    };

    getInvoiceStreamTotalDue = (invoiceSummary) => {
        const overdueStat = this.aggregateBilling(
            _.filter(invoiceSummary, (invoice) => {
                return invoice.invoiceStatus === 'due';
            })
        );
        const billedStat = this.aggregateBilling(
            _.filter(invoiceSummary, (invoice) => {
                return invoice.invoiceStatus === 'billed';
            })
        );
        const amountDue = {
            amount: 0,
            currency: this.localeService.getDefaultCurrencyCode()
        };
        if (overdueStat && billedStat) {
            amountDue.amount = overdueStat.amountDue.amount + billedStat.amountDue.amount;
            amountDue.currency = overdueStat.amountDue.currency;
        } else if (overdueStat) {
            amountDue.amount = overdueStat.amountDue.amount;
            amountDue.currency = overdueStat.amountDue.currency;
        } else if (billedStat) {
            amountDue.amount = billedStat.amountDue.amount;
            amountDue.currency = billedStat.amountDue.currency;
        }
        return amountDue;
    };

    handleMakeAPayment = () => {
        const { history } = this.props;
        const { billingData, xCenter } = this.state;
        return history.push({
            pathname: `/pay-invoices/billed/${billingData.getAccountDetails.accountNumber}`,
            state: {
                accountNumber: billingData.getAccountDetails.accountNumber,
                xCenter: xCenter,
                billingData
            }
        });
    };

    handleSetupAutomaticPayment = (invoiceStreamIndex) => {
        const { billingData } = this.state;
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        const invoiceStream = billingData
            .getAccountBillingDetails.paymentGroups[invoiceStreamIndex];

        this.setState({ showInnerLoader: true, isSetupPayment: true });
        GatewayBillingService.getPaymentGroupPaymentInformation(
            accountNumber, invoiceStream.internalId, authHeader
        ).then(
            (responseData) => {
                billingData.paymentGroup = responseData;
                this.setState({
                    billingData,
                    showInnerLoader: false
                });
            }
        );
    };

    handleCancelSetupPayment = () => {
        this.setState({ showInnerLoader: false, isSetupPayment: false });
    };

    handleReturnSetupPayment = () => {
        const { billingData } = this.state;
        this.setState({ showInnerLoader: true });
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            }
        } = this.props;
        GatewayBillingService.getAccountBillingSummary(accountNumber, authHeader).then(
            (response) => {
                billingData.getAccountBillingDetails = _.isEmpty(response)
                    ? { paymentGroups: [] }
                    : response;
                this.setState({
                    billingData,
                    showInnerLoader: false,
                    isSetupPayment: false,
                    isSetupPaymentComplete: false
                });
            }
        );
    };

    handleConfirmSetupPayment = (paymentData, paymentMethod) => {
        const {
            authHeader,
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            },
            modalContext
        } = this.props;
        const { billingData } = this.state;
        const invoiceStreamInternalId = billingData.paymentGroup.internalId;

        const paymentInstrument = {};

        if (paymentMethod === 'wire') {
            const bankAccountData = {
                bankAccountType: _.get(paymentData, 'bankAccountType.value.code'),
                bankAccountNumber: _.get(paymentData, 'bankAccountNumber.value'),
                bankABANumber: _.get(paymentData, 'bankABANumber.value'),
                bankName: _.get(paymentData, 'bankName.value')
            };

            paymentInstrument.paymentMethod = paymentMethod;
            paymentInstrument.bankAccountData = bankAccountData;
            paymentInstrument.creditCardData = null;
        } else {
            const creditBankAccountData = {
                creditCardIssuer: _.get(paymentData, 'creditCardIssuer.value.code'),
                creditCardNumber: _.get(paymentData, 'creditCardNumber.value'),
                creditCardExpDate: _.get(paymentData, 'creditCardExpDate.value')
            };

            paymentInstrument.paymentMethod = 'creditcard';
            paymentInstrument.creditCardData = creditBankAccountData;
            paymentInstrument.bankAccountData = null;
        }

        this.setState({ showInnerLoader: true });
        GatewayBillingService.setPaymentGroupPaymentInformation(
            accountNumber,
            invoiceStreamInternalId,
            paymentInstrument,
            authHeader
        )
            .then(() => {
                this.setState({
                    showInnerLoader: false,
                    isSetupPayment: false,
                    isSetupPaymentComplete: true
                });
            })
            .catch(() => {
                modalContext.showAlert({
                    title: messages.setupPaymentFailed,
                    message: messages.setupPaymentFailedSorry,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            });
    };

    getAutomaticPaymentScheduleTitle = () => {
        const translator = this.context;
        const { billingData } = this.state;
        return translator(messages.setupAutomaticPaymentSchedule,
            { displayName: billingData.paymentGroup.displayName });
    };

    getAutomaticPaymentScheduleMessage = () => {
        const translator = this.context;
        const { billingData } = this.state;
        const {
            paymentGroup: { displayName = '' }
        } = billingData;
        const displayValue = displayName.replace(/[\d()]/g, '').trim();
        let formattedDate = billingData.paymentGroup.scheduleTerms
            ? billingData.paymentGroup.scheduleTerms[0].startDate
            : '';
        formattedDate = formatDate(this.props.intl, {}, formattedDate);

        return translator(messages.scheduleTermDescription, {
            periodicity: displayValue,
            startDate: formattedDate
        });
    };

    getAutomaticPaymentScheduleDueDate = () => {
        const translator = this.context;
        const { billingData } = this.state;
        const formattedDate = billingData.paymentGroup.scheduleTerms
            ? billingData.paymentGroup.scheduleTerms[0].startDate
            : '';
        const dueDate = formatDate(this.props.intl, {}, formattedDate);
        return translator(messages.setupPaymentConfirmDue, { dueDate: dueDate });
    };

    getPaymentConfirmPhone = () => {
        const translator = this.context;
        return translator(messages.setupPaymentConfirmPhone, { supportPhone: config.supportPhone });
    };

    getTotalPremium = () => {
        const { billingData } = this.state;
        let totalPremium = 0;
        _.each(billingData.paymentGroup.invoiceSummary, (invoice) => {
            totalPremium += invoice.amountDue.amount;
        });
        return totalPremium;
    };

    handleTogglePaymentSchedule = () => {
        const { isDetailedScheduleOpen } = this.state;
        this.setState({
            isDetailedScheduleOpen: !isDetailedScheduleOpen
        });
    };

    onCancelPayment = () => {
        const {
            fromAccountLanding: {
                accountDetailsData: { accountNumber }
            },
            history
        } = this.props;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({
            pathname: nextPath,
            ...this.props,
            accountDetailsData: {
                accountNumber: accountNumber
            }
        });
    };

    render() {
        const translator = this.context;
        const {
            billingData,
            loaded,
            showInnerLoader,
            isSetupPayment,
            isSetupPaymentComplete,
            isDetailedScheduleOpen,
            noBillingData,
            xCenter
        } = this.state;
        if (!loaded) {
            return (
                <Loader loaded={loaded} />
            );
        }
        if (_.isEmpty(billingData)) {
            return true;
        }

        const overrideProps = {
            billingPagePrimaryPayerName: {
                content: _.get(billingData, 'getAccountDetails.accountHolder.displayName')
            },
            billingPageAccountStatusIcon: {
                icon: this.getAccountStatusIcon(billingData),
                className: this.getAccountStatusClassname(billingData)
            },
            billingPageAccountDelinquencyLevel: {
                value: this.getDelinquenyLevel(_.get(billingData,'getAccountDetails.accountBillingSummary'))
            },
            billingPageAccountDelinquencyLevelDate: {
                value: this.getDelinquenyLevelDate(_.get(billingData,'getAccountDetails.accountBillingSummary'))
            },
            tabBillingMakeAPayment: {
                visible:
                    _.get(billingData, 'getAccountBillingData.totalDue')
                    && _.get(billingData, 'getAccountBillingData.totalDue.amount') > 0
            },
            tabBillingPoliciesOwnedTable: {
                data: billingData.getAccountBillingData.ownedPolicies
            },
            tabBillingPoliciesUnownedTable: {
                data: billingData.getAccountBillingData.unownedPolicies
            },
            tabAutomaticPaymentsContent: {
                visible: !isSetupPayment && !isSetupPaymentComplete && !noBillingData,
                data: billingData.getAccountBillingDetails.paymentGroups
            },
            setupAutomaticPaymenetsSetup: {
                visible: isSetupPayment && !showInnerLoader
            },
            setupAutomaticPaymentsSuccess: {
                visible: isSetupPaymentComplete && !showInnerLoader
            },
            setupPaymentLoader: {
                visible: showInnerLoader,
                loaded: !showInnerLoader
            },
            tabAutomaticPaymentsScheduleTitle: {
                content: this.getAutomaticPaymentScheduleTitle()
            },
            paymentScheduleMessageLabelDate: {
                content: this.getAutomaticPaymentScheduleMessage()
            },
            paymentSchedulePremium: {
                value: this.getTotalPremium()
            },
            paymentScheduleDetailToggleLink: {
                icon: isDetailedScheduleOpen ? 'mi-arrow-drop-up' : 'mi-arrow-drop-down',
                content: isDetailedScheduleOpen
                    ? translator(messages.hideDetailedSchedule)
                    : translator(messages.showDetailedSchedule)
            },
            detailedScheduleContainer: {
                visible: isDetailedScheduleOpen
            },
            detailedScheduleTable: {
                data: billingData.paymentGroup.invoiceSummary
            },
            paymentSetupSuccessNextPayment: {
                content: this.getSetupPaymentNextPayment(billingData.paymentGroup.invoiceSummary)
            },
            paymentSetupSuccessDue: {
                content: this.getAutomaticPaymentScheduleDueDate()
            },
            paymentSetupSuccessPhone: {
                content: this.getPaymentConfirmPhone()
            },
            noBillingData: {
                visible: _.isEmpty(_.get(billingData,'getAccountDetails.accountBillingSummary'))
            },
            billingPagePayer: {
                visible: !_.isEmpty(_.get(billingData,'getAccountDetails.accountBillingSummary'))
            },
            paymentComponent: {
                xCenter: xCenter,
                isSetupPayment: isSetupPayment,
                nextLabel: isSetupPayment
                    ? translator(messages.setupPaymentConfirm)
                    : translator(makePaymentMessages.payNowLabel)
            },
            ...this.generateAutomaticBillingOverrides(),
            ...this.generateSetupPaymentOverrides()
        };

        const resolvers = {
            resolveClassNameMap: accountBillingStyles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getPolicyLink: this.getPolicyLink,
                getFormattedDatePV: this.getFormattedDatePV,
                getFormattedCurrency: this.getFormattedCurrency,
                getAltBillingAccount: this.getAltBillingAccount,
                getDelinquenyLevel: this.getDelinquenyLevel,
                getDelinquenyLevelDate: this.getDelinquenyLevelDate,
                getBillStatus: this.getBillStatus,
                getFormattedCurrencyPaymentSchedule: this.getFormattedCurrencyPaymentSchedule,
                handleMakeAPayment: this.handleMakeAPayment,
                handleTogglePaymentSchedule: this.handleTogglePaymentSchedule,
                getFormattedCurrencyCharges: this.getFormattedCurrencyCharges,
                handleCancelSetupPayment: this.handleCancelSetupPayment,
                handleConfirmSetupPayment: this.handleConfirmSetupPayment,
                handleReturnSetupPayment: this.handleReturnSetupPayment,
                onCancelPayment: this.handleCancelSetupPayment,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                sortCurrency: DatatableUtil.sortCurrency
            },
            resolveComponentMap: {
                paymentcomponent: PaymentComponent,
                automaticpaymentpolicyaffectedvalueiterablecomponent:
                    AutomaticPaymentPolicyAffectedValueIterableComponent
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, billingData, id, path, overrideProps);
        };

        return (
            <div className={accountBillingStyles.accountbilling}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={billingData}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </div>
        );
    }
}

export const AccountBillingAndPaymentComponent = AccountBillingAndPayment;
export default withIntl(withViewModelService(withAuthenticationContext(withModalContext(AccountBillingAndPayment))));
